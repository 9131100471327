import React from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import ThreeColCta from '../../../components/ThreeColCta';
import VideoIframe from '../../../components/VideoIframe';
import LandingPageCta from '../../../components/LandingPageCta';

const HorizonApp = () => (
	<Layout>
		<Seo
			title="Horizon App - Links to download and how-to-use video | hear.com"
			description="Find on this page links to download the Horizon App for iPhone or Android phones and stay always connected with the best hearing aids technology. "
		/>
		<div className="o-wrapper">
			<div className="o-row-2p5">
				<div className="c-newsletter-img">
					<img
						className="o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-hearing-aids-app.png"
						alt="man sitting on couch and smiling"
						loading="lazy"
					/>
				</div>
			</div>
			<div className="o-row-2p5">
				<h1 className="c-content-hero__headline">Download Horizon App</h1>
			</div>
			<ul className="c-usps">
				<li>Hearing aid and smartphone perfectly connected </li>
				<li>Simple, intuitive user interface </li>
				<li>Stream and make telephone calls directly via the hearing aid</li>
			</ul>
			<div className="c-app-box">
				<a
					className="c-app-box__panel c-app-box__android"
					href="https://play.google.com/store/apps/details?id=com.heardotcom.rta&hl=en_US&gl=US"
				>
					<p className="c-app-box__android-title">Android</p>
					<img
						className="c-app-img o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/android-small.png"
						alt=""
					/>
				</a>
				<a
					className="c-app-box__panel c-app-box__ios"
					href="https://apps.apple.com/us/app/hear-com-horizon/id1489152822"
				>
					<p className="c-app-box__ios-title">iOS</p>
					<img
						className="c-app-img o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/ios-small.png"
						alt=""
					/>
				</a>
			</div>
			<section className="c-app-section">
				<div className="o-row-2p5">
					<h2 className="c-app-section__h2">hear.com Horizon app</h2>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						If your cell phone model has Bluetooth you can listen to music or audio-books directly via your hearing
						systems. Complete control made really easy. With the Horizon app you can manage the settings and streaming
						content discreetly via your iPhone or Android smartphone.
					</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						With StreamLine TV you can receive the sound of the television directly in your ears. StreamLine Mic is a
						real help during hands-free telephone calls. Take calls directly via the discreet clip-on microphone.
					</p>
				</div>
				<div className="o-row-2p5">
					<h2 className="c-app-section__h2">Horizon app exclusive functions</h2>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						Fully customize your hearing experience with the easy-to-use Horizon app. Stream music, tv shows, movies,
						and more directly to your hearing aids or take advantage of exclusive functions like Speech Focus, Panorama
						Mode, and My Modes (music, active, quiet, and relax mode).
					</p>
				</div>
				<div className="o-row-2p5">
					<h3 className="c-app-section__h3">Speech Focus</h3>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						With Speech Focus, you can concentrate on what truly matters. Enhance your speech clarity in one-on-one or
						group conversations using Speech Focus. A simple tap in the Horizon app filters out background noise and
						amplifies the voices you want to hear. Horizon IX ensures effortless conversation in any setting.
					</p>
				</div>
				<div className="o-row-2p5">
					<h3 className="c-app-section__h3">Panorama Effect</h3>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						With Panorama Effect, you can fully experience nature's subtle sounds. The crunch of leaves underfoot, the
						rustle of wind in the treetops, the chirping of birds. Whether you're jogging, hiking, cycling, or simply
						sitting on a bench, Panorama Effect lets you immerse yourself in the sounds of nature with just a touch.
						Experience nature not only as a visual landscape but as an enveloping soundscape.
					</p>
				</div>
				<div className="o-row-2p5">
					<h3 className="c-app-section__h3">My Modes</h3>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						Every person is unique, with distinct lifestyles and listening habits. That's why we developed My Modes to
						personalize your hearing aid to your specific preferences and needs.
						<br></br>
						<br></br>
						<strong>Music Mode:</strong> Enjoy your favorite songs in their full glory with music mode. Designed to
						bring music to life, every note, detail, and tone is crisp for the ultimate listening experience.
						<br></br>
						<br></br>
						<strong>Quiet Mode:</strong> Sometimes, silence is the most beautiful sound. When you need a break from
						life's noise, quiet mode suppresses sound, allowing you to enjoy some peace and quiet.
						<br></br>
						<br></br>
						<strong>Active Mode:</strong> Horizon IX is the first hearing aid designed for active, younger individuals.
						Whether you’re biking, gardening, or golfing with friends, active mode keeps you connected.
						<br></br>
						<br></br>
						<strong>Relax Mode:</strong> Immerse yourself in soothing ambient noise with relax mode. Indulge in the
						calming sounds of nature for moments of pure, uninterrupted bliss.
					</p>
				</div>
				<div className="o-row-2p5">
					<h2 className="c-app-section__h2">Horizon App Tutorial Video</h2>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						In just five minutes, you'll learn everything you need to know about our innovative hearing aids app. From
						setting up and personalizing your hearing experience to utilizing advanced features like My Modes and Speech
						Focus, this video will guide you through each step with ease. Whether you're a new user or looking to
						maximize the benefits of your Horizon hearing aids, this tutorial is designed to ensure you get the most out
						of your device. Dive in and discover how the Horizon App can transform your listening experience.
					</p>
				</div>
			</section>
			<VideoIframe
				title="Horizon App Tutorial – How to Use the Horizon App by hear.com in less than 5 Minutes"
				width="560"
				height="315"
				src="https://www.youtube.com/embed/mBiyjkcXLgg?si=zB8CL36uWO1rRICH"
			/>
			<br></br>
			<br></br>
			<div className="o-row-2p5">
				<h2 className="c-app-section__h2">Contact a hearing specialist</h2>
			</div>
			<div className="o-row-2p5">
				<p className="c-app-section__copy">
					If you need any assistance setting up the Horizon App or have questions about your hearing aids, our dedicated
					team of hearing specialists at hear.com is here to help. We're committed to providing support every step of
					the way. Don't hesitate to reach out to us for personalized guidance and expert advice. Contact us today, and
					let us ensure you have a seamless and successful experience with your new hearing aids.
				</p>
				<br></br>
				<br></br>
				<div className="u-text-center">
					<LandingPageCta copy="Contact a hearing specialist" classes="c-cta c-cta--brand" />
				</div>
			</div>
			<br></br>
			<br></br>
			<div className="o-row-2p5">
				<h2 className="c-app-section__h2">Resources</h2>
			</div>
			<div className="o-row-2p5">
				<ThreeColCta
					ImgSrc1="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg"
					ContentTitle1="Hearing Test"
					ContentCopy1="Check how is your hearing in less than 5 minutes."
					Url1="/hearing-test/"
					ImgSrc2="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg"
					ContentTitle2="Hearing Loss"
					ContentCopy2="There are several types of hearing loss and many different cause."
					Url2="/hearing-loss/"
					ImgSrc3="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg"
					ContentTitle3="Hearing Aids"
					ContentCopy3="Connect you to the world around you with our wide range of devices."
					Url3="/hearing-aids/"
				/>
			</div>
		</div>
	</Layout>
);

export default HorizonApp;
